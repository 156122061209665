class SetOnboardingCookie {
  ready() {
    function setOnboardingCompleteCookie() {
      const expirationDate = new Date();
      expirationDate.setFullYear(expirationDate.getFullYear() + 50);
      const expires = `expires=${expirationDate.toUTCString()}`;
      // eslint-disable-next-line no-undef
      document.cookie = `onboarding_complete=true; path=/; SameSite=Lax; ${expires}`;
    }

    function removeOnboardingCompleteCookie() {
      // eslint-disable-next-line no-undef
      document.cookie =
        'onboarding_complete=; path=/; SameSite=Lax; expires=Thu, 01 Jan 1970 00:00:00 UTC;';
    }

    // eslint-disable-next-line no-undef
    const skipButton = document.querySelector('#skip-onboarding');
    if (skipButton) {
      skipButton.addEventListener('click', function () {
        setOnboardingCompleteCookie();
        // eslint-disable-next-line no-undef
        window.location.href = '/';
      });
    }

    // eslint-disable-next-line no-undef
    const learningActivityButton = document.querySelector('#learning-activity');
    if (learningActivityButton) {
      learningActivityButton.addEventListener('click', function () {
        removeOnboardingCompleteCookie();
        // eslint-disable-next-line no-undef
        window.location.href = '/';
      });
    }
  }
}

export default new SetOnboardingCookie();
