class OnboardingCardHelper {
  ready() {
    document.addEventListener('htmx:afterRequest', function (evt) {
      if (evt.detail.target.id == 'onboarding-cards-container') {
        const cardId = document
          .querySelector('div.onboarding-card')
          .getAttribute('data-card-id');
        document.querySelector('input[name="current"]').value = cardId;
        // Update the indicators to reflect the active card

        const indicators = document.querySelectorAll('.onboarding-indicator');
        indicators.forEach(function (indicator) {
          if (indicator.getAttribute('data-card-id') == cardId) {
            indicator.classList.add('active');
          } else {
            indicator.classList.remove('active');
          }
        });
      }
    });

    // Add click event listener to indicators
    document
      .querySelectorAll('.onboarding-indicator')
      .forEach(function (indicator) {
        indicator.addEventListener('click', function () {
          const cardId = this.getAttribute('data-card-id');
          document.querySelector('input[name="current"]').value = cardId; // Set current card ID to the clicked one

          fetch(window.location.href, {
            method: 'POST',
            headers: {
              'HX-Request': 'true',
              'X-CSRFToken': document.querySelector(
                'input[name="csrfmiddlewaretoken"]'
              ).value,
            },
            body: new URLSearchParams({
              card_id: cardId,
            }),
          })
            .then((response) => response.text())
            .then((html) => {
              document.querySelector('#onboarding-cards-container').innerHTML =
                html;
              // Update the indicators to reflect the newly selected card
              const indicators = document.querySelectorAll(
                '.onboarding-indicator'
              );
              indicators.forEach(function (indicator) {
                if (indicator.getAttribute('data-card-id') == cardId) {
                  indicator.classList.add('active');
                } else {
                  indicator.classList.remove('active');
                }
              });
            });
        });
      });
  }
}

export default new OnboardingCardHelper();
