import OnboardingCardHelper from '../utils/onboarding-card-helper';
import SetOnboardingCookie from '../utils/set-onboarding-cookie';

/**
 * Run Script on site load
 */

document.addEventListener('DOMContentLoaded', () => {
  (() => {
    SetOnboardingCookie.ready();
    OnboardingCardHelper.ready();
  })();
});
